import axios from "axios";
import config from "../../config";
import store from "../index";

export default {
  state: {
    
  },
  getters: {},
  actions: {
    GET_ARTISTS_LIST: async ({ commit }) => {
      try {
        const GetArtistList = await axios.get(`artist-list`);
        return GetArtistList;
      } catch (e) {
        Promise.reject(e);
      }
    },
  },
  mutations: {
    
  },
};
