import axios from "axios";
import config from "./config";
import store from "./store/index";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

const toast = (data) => {
  createToast(data);
};

// let toast = VueToast();
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.Authorization =
  localStorage.getItem("token_type") + " " + localStorage.getItem("token");
axios.defaults.baseURL = config.APIURL;
axios.interceptors.request.use(
  (config) => {
    // $store.state.showLoading =true;
    store.dispatch("createEvent", true);

    // let params = new URLSearchParams();
    // // params.append('hai');
    // config.params = params;
    // console.log(config, 'axios config');
    // console.log(params, 'axios params')
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  function (response) {
    store.dispatch("createEvent", false);
    // console.log(response.data.message,'responseresponseresponseresponse');
    if (response.data.message) {
      toast(response.data.message);
    }
    return response;
  },
  async function (error) {
    toast(error.response.data.message);
    store.dispatch("createEvent", false);
    return Promise.reject(error);
  }
);

export default {
  axios,
};
