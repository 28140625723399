<template>
    <section class="pb-5 pt-3 login-section">
        <div class="container pb-5">
            <div class="hero-section">
                <div class="hero-section-content text-center">
                    <h2 class="heading-1 pb-0 mb-0">
                        We do things differently...
                    </h2>
                    <p class="hero-section-description mt-3">
                        We focus on the details of everything we do. All to help
                        businesses around the world focus on what’s most
                        important to them. We take pride in this.
                    </p>
                </div>
                <div class="hero-section-img">
                    <img src="../assets/img/hero-img.svg" class="img-fluid" />
                </div>
            </div>
            <TeamEmployeeComponent></TeamEmployeeComponent>
        </div>
    </section>
            <EmployeesProfileComponent></EmployeesProfileComponent>
</template>

<script>
import EmployeesProfileComponent from "./About/EmployeesProfileComponent.vue";
import TeamEmployeeComponent from "./About/TeamEmployeeComponent.vue";

export default {
    components: {
        EmployeesProfileComponent,
        TeamEmployeeComponent,
    },
};
</script>
