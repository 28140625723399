<template>
    <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-12">
          <h2 class="heading-1 pb-4">Delivery Address</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <img class="img-fluid" src="../../assets/img/p-1.png" />
                </div>
                <div class="col-lg-10">
                  <a href="#">
                    <h4 class="card-h-1">
                      OPPO Enco Buds With 24 hours Battery Life Bluetooth H
                    </h4>
                    <h6 class="card-h-2">by username</h6>
                    <h6 class="card-h-3">$1200</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <img class="img-fluid" src="../../assets/img/p-2.png" />
                </div>
                <div class="col-lg-10">
                  <a href="#">
                    <h4 class="card-h-1">
                      OPPO Enco Buds With 24 hours Battery Life Bluetooth H
                    </h4>
                    <h6 class="card-h-2">by username</h6>
                    <h6 class="card-h-3">$1200</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <img class="img-fluid" src="../../assets/img/p-3.png" />
                </div>
                <div class="col-lg-10">
                  <a href="#">
                    <h4 class="card-h-1">
                      OPPO Enco Buds With 24 hours Battery Life Bluetooth H
                    </h4>
                    <h6 class="card-h-2">by username</h6>
                    <h6 class="card-h-3">$1200</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <a href="#" class="art-button d-block text-center mt-2"
                >PLACE ORDER</a
              >
            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default {
    
}
</script>
