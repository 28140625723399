<template>
  <div
    class="card mb-3"
    v-for="product in this.$store.state.cart.cartItems"
    :key="product.id"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-lg-2">
          <img class="img-fluid" src="../../assets/img/p-1.png" />
        </div>
        <div class="col-lg-8">
          <div>
            <h4 class="card-h-1 fw-bold">Name:- {{ product.name }}</h4>
            <h6 class="card-h-3 fw-bold">
              Price/Unit:- {{ product.unit_price }}
            </h6>
            <div class="d-flex align-items-center">
              <h6 class="card-h-2 fw-bold mb-0">Quantity:-</h6>

              <button
                class="btn btn-outline-dark"
                style="padding: 0px 6px; margin-left: 0.5rem !important" 
                v-bind:disabled="product.quantity == 1"
                @click="minusCart(product.cart_id,product.quantity)"
              >
                -
              </button>
              <div class="px-2">
                {{ product.quantity }}
              </div>
              <button
                class="btn btn-outline-dark px-1 py-0"
                v-bind:disabled="product.quantity == 5"
                @click="increaseCart(product.cart_id,product.quantity)"
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center">
          <div>
            <button
              @click="deleteCartItem(product.cart_id)"
              class="btn btn-sm delete-btn"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    deleteCartItem(cart_id) {
      this.$store
        .dispatch("DELETECARTITEM", cart_id)
        .then((success) => {
          if (success) {
            this.$store
              .dispatch("GET_CART_DEATILS")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
            this.$store
              .dispatch("GET_CART_SUMMARY")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
          }
        })
        .catch((error) => {});
    },
    increaseCart(id,quantity) {
      let fromData = {
        id:id,
        quantity:quantity + 1
      }
      this.$store
        .dispatch("UPDATECARTQUANTITY", fromData)
        .then((success) => {
          if (success) {
            this.$store
              .dispatch("GET_CART_DEATILS")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
            this.$store
              .dispatch("GET_CART_SUMMARY")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
          }
        })
        .catch((error) => {});
    },
    minusCart(id,quantity) {
      if(quantity > 1){

      let fromData = {
        id:id,
        quantity:quantity - 1
      }
      this.$store
        .dispatch("UPDATECARTQUANTITY", fromData)
        .then((success) => {
          if (success) {
            this.$store
              .dispatch("GET_CART_DEATILS")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
            this.$store
              .dispatch("GET_CART_SUMMARY")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
          }
        })
        .catch((error) => {});
      }

    },
    
  },
};
</script>
<style scoped>
.delete-btn {
  background: linear-gradient(268.63deg, #efb933 0%, #f95373 51.17%);
  box-shadow: 0px 8px 16px rgb(249 83 115 / 20%);
  color: #fff;
  margin: 0px 10px;
  border: none;
}
</style>
