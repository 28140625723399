import axios from "axios";
import config from "../../config";
import store from "../index";

export default {
  state: {
    address: [],
    addressList:[],
    addressById:{},
    addressIdForCart:null
  },
  getters: {},
  actions: {
    GET_COUNTRY_DETAILS: async ({ commit }) => {
      try {
        const GetCountryDetails = await axios.get(`countries`);
        return GetCountryDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_STATE_DETAILS: async ({ commit },id) => {
      try {
        const GetStateDetails = await axios.get(`states?country_id=${id}`);
         return GetStateDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_CITY_DETAILS: async ({ commit },id) => {
      try {
        const GetCityDetails = await axios.get(`cities?state_id=${id}`);
         return GetCityDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_ADDRESS_DETAILS: async ({ commit }) => {
      try {
        const GetAddressDetails = await axios.get(`user/shipping/address`);
        commit("setAddressList", GetAddressDetails);
         return GetAddressDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_ADDRESS_DETAILS_BY_ID: async ({ commit },id) => {
      try {
        const GetAddressDetailsById = await axios.get(`user/shipping/address?id=${id}`);
        store.dispatch('GET_ADDRESS_DETAILS');
         return GetAddressDetailsById;
      } catch (e) {
        Promise.reject(e);
      }
    },
    ADD_ADDRESS: async ({ commit }, formData) => {
      try {
        const addAddress = await axios.post(`user/shipping/create`, formData);
        commit("setAddressList", addAddress);
        return addAddress;
      } catch (e) {
        Promise.reject(e);
      }
    },
    UPDATE_ADDRESS: async ({ commit }, formData) => {
      try {
        const addAddress = await axios.post(`user/shipping/update`, formData);
        commit("setAddressList", addAddress);
        return addAddress;
      } catch (e) {
        Promise.reject(e);
      }
    },
    DELETE_ADDRESS: async ({ commit }, cart_id) => {
      try {
        const deleteCartItem = await axios.post(`carts/delete`, {
          cart_id: cart_id,
        });
        return deleteCartItem;
      } catch (e) {
        Promise.reject(e);
      }
    },
    CONFIRM_ORDER: async ({ commit }, data) => {
      try {
        const confirmOrder = await axios.post(`confirm-order`, data);
        return confirmOrder;
      } catch (e) {
        Promise.reject(e);
      }
    },
    UPDATE_FLAG: async ({ commit }, data) => {
      commit("setFlag", data);
    },
    
  },
  mutations: {
    setAddressList(state, data) {
      state.addressList = data.data.data;
    },
    setAddressById(state, data) {
      state.addressById = data.data.data[0];
    },
    setFlag(state, data) {
      state.flag = data;
    },
    updateCartAddress(state, data) {
      state.addressIdForCart = data
    },
  },
};
