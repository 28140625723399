<template>
  <section class="py-5">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-12">
          <h2 class="heading-1 pb-4">Order History</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12" v-if="orderList.length > 0">
          <div class="card mb-3" v-for="order in orderList" :key="order.id">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-1">
                  <img class="img-fluid" src="../../assets/img/p-1.png" />
                </div>
                <div class="col-lg-8">
                  <router-link :to="'/order-details/' + order.id">
                    <h4 class="card-h-1">
                      {{ order?.product?.name }}
                    </h4>
                  </router-link>
                  <h6 class="card-h-2">Payment- {{ order.payment_status }}</h6>
                  <h6 class="card-h-3">Rs {{ order.price }}</h6>
                </div>
                <div class="col-lg-3">
                  <h6 class="text-success">
                    {{ order.delivery_status }} on
                    {{
                      new Date(order.updated_at).toISOString().substring(0, 10)
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center" v-else>
          <div class="col-lg-8">
            <div class="py-5 cart-section">
              <img
                src="../../assets/img/cart-empty.jpg"
                alt="img"
                class="w-25"
              />
              <h5>You Have No Orders</h5>
              <p>Please order Products</p>
              <router-link class="art-button border-0" to="/products"
                >Shop Now</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import order from "@/store/modules/order";
export default {
  data() {
    return {
      orderList: [],
    };
  },
  mounted() {
    this.$store
      .dispatch("GET_ORDER_DEATILS")
      .then((success) => {
        let array = [];
        if (success.status == 200) {
          let data = success.data.data;
          for (let i = 0; i < data.length; i++) {
            let orderData = data[i].product_id;
            for (let j = 0; j < orderData.length; j++) {
              let orders = orderData[j];
              array.push(orders);
            }
          }
          this.orderList = array;
          console.log(this.orderList);
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
};
</script>
