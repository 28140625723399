<template>
  <section class="pb-5 pt-3 login-section">
    <div class="container pb-5">
      <div class="row h-100 d-flex justify-content-center align-items-center">
        <div class="col-lg-5 text-center">
          <h2 class="heading-1 pb-3">Sign Up</h2>

          <p class="px-4">
            Vitae vel sit nam iaculis auctor libero nisl. Dui a diam nam laoreet
            sed. At placerat lectus ultrices
          </p>

          <ul class="nav nav-tabs signup-tabs row" id="myTab" role="tablist">
            <li class="nav-item col-lg-6 col-sm col-6" role="presentation">
              <div
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <img src="../assets/img/Frame-36.png" class="img-fluid" />
                <h4>Artist Sign Up</h4>
                <p>Ipsum sem egestas scelerisque urna eros, nisl at.</p>
              </div>
            </li>
            <li class="nav-item col-lg-6 col-sm col-6" role="presentation">
              <div
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <img src="../assets/img/Frame-35.png" class="img-fluid" />
                <h4>Customar Sign Up</h4>
                <p>Ipsum sem egestas scelerisque urna eros, nisl at.</p>
              </div>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <Form
                @submit="submit"
                :validation-schema="simpleSchemaArtist"
                class="mt-8 space-y-6"
                action="#"
                method="POST"
              >
                <!-- Artist Sign Up -->
                <ArtistRegComponent :artistList="artistList"/>
              </Form>
            </div>

            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <Form
                @submit="submit"
                :validation-schema="simpleSchemaCus"
                class="mt-8 space-y-6"
                action="#"
                method="POST"
              >
                <!-- Customar Sign Up -->
                <CustomerRegComponent />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ArtistRegComponent from "./Register/ArtistRegComponent.vue";
import CustomerRegComponent from "./Register/CustomerRegComponent.vue";
import * as yup from "yup";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    ArtistRegComponent,
    CustomerRegComponent,
  },

  data() {
    const simpleSchemaArtist = yup.object({
      email_or_phone: yup.string().required().email(),
      password: yup.string().required().min(6),
      shop_name: yup.string().required().min(6),
       artist_type: yup.string().required()
    });
    const simpleSchemaCus = yup.object({
      email_or_phone: yup.string().required().email(),
      password: yup.string().required().min(6),
      // shop: yup.string().required().min(6),
      user_name: yup.string().required().min(5),
    });
    return {
      simpleSchemaArtist,
      simpleSchemaCus,
      artistList:[],
    };
  },

  methods: {
    submit(values, { resetForm }) {
      this.$store
        .dispatch("REGISTER", values)
        .then((success) => {
          console.log(success);
          if (success.status == 201) {
          this.$router.push('/login')
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
  },
  beforeMount() {
    this.$store
      .dispatch("GET_ARTISTS_LIST")
      .then((success) => {
        this.artistList = success.data;
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
};
</script>
