<template>
  <section class="pb-5 pt-3 login-section">
    <div class="container pb-5">
      <Form
        @submit="submit"
        :validation-schema="simpleSchema"
        class="mt-8 space-y-6"
        action="#"
        method="POST"
      >
        <div class="row h-100 d-flex justify-content-center align-items-center">
          <div class="col-lg-5 text-center">
            <h2 class="heading-1 pb-3">Login</h2>

            <p class="px-4">
              Vitae vel sit nam iaculis auctor libero nisl. Dui a diam nam
              laoreet sed. At placerat lectus ultrices
            </p>
            <div class="row mt-4">
              <div class="col-lg-12 text-start mb-3">
                <label for="email-address" class="mb-2"
                  >Username Or Email Address</label
                >
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <img src="../assets/img/mail.png" class="img-fluid ps-3" />
                  <Field
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Enter email or username"
                  />
                </div>
                <ErrorMessage name="email" />
              </div>
              <div class="col-lg-12 text-start">
                <label for="password" class="sr-only">Password</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <img src="../assets/img/lock.png" class="img-fluid ps-3" />
                  <Field
                    name="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                  />
                </div>
                <ErrorMessage name="password" />
              </div>
              <div class="col-lg-12 text-start">
                <label class="mb-2">User Type *</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <Field
                    name="user_type"
                    as="select"
                    class="form-select form-control"
                  >
                    <option value="">Select Type of User Type</option>
                    <option value="customer" >
                      Customer
                    </option>
                    <option value="Artist" >
                      Artist
                    </option>
                  </Field>
                </div>
                <ErrorMessage name="user_type" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="row mt-4 h-100 d-flex justify-content-center align-items-center sec-button-div"
        >
          <div class="col-lg-5 text-center">
            <button class="art-button signin-btn d-block w-100 border-0">
              Login
            </button>
            <p>
              Don’t have an account ?
              <router-link class="" :to="'/register'">Sign Up</router-link>
            </p>
          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  // mounted() {
  //     console.log('Login mounted.')
  // },
  data() {
    const simpleSchema = yup.object({
      email: yup.string().required().email(),
      password: yup.string().required().min(6),
      user_type: yup.string().required(),
      submitted: false,
      error: false,
    });
    return {
      simpleSchema,
    };
  },

  methods: {
    submit(values, { resetForm }) {
      // this.$store.dispatch('createEvent', true)
      this.$store
        .dispatch("LOGIN", values)
        .then((success) => {
          if (success.user) {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
@import "http://fonts.cdnfonts.com/css/brown-shoes";
@import "http://fonts.cdnfonts.com/css/lilita-one";
</style>
