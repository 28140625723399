<template>
  <section class="featured-design py-5 bg-white">
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-5">
          <img src="../assets/img/artist-profile.png" class="img-fluid w-100" />
        </div>
        <div class="col-lg-7">
          <div class="others-in">
            <div class="row mt-4">
              <div class="col-lg-12 text-start mb-3">
                <label class="mb-2">Title</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Use 4-8 words to discribe your work"
                  />
                </div>
              </div>
              <div class="col-lg-12 text-start mb-3">
                <label class="mb-2">Tags</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Separate tags with commas"
                  />
                </div>
              </div>
              <div class="col-lg-12 text-start mb-3">
                <label class="mb-2">Discription</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <textarea
                    class="form-control"
                    rows="4"
                    placeholder="Discribe your work to get you audience excited."
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12 text-start mb-3">
                <label class="mb-2">Medias</label>

                <div class="row mb-2">
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Photography
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Design & Illustration
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        Printing & Media
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault4"
                      />
                      <label class="form-check-label" for="flexRadioDefault4">
                        Digital Art
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ArtistProductPreviewCard />
  </section>
</template>

<script>
import ArtistProductPreviewCard from "../components/Art/ArtistProductPreview.vue";
export default {
  components: {
    ArtistProductPreviewCard,
  },
};
</script>
