<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="'/'">
        <img src="../../assets/img/dude-logo.png" class="img-fluid" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <form class="d-flex w-45">
          <input
            class="form-control ms-5 w-100"
            type="search"
            placeholder="Search for product, designs or artist"
            aria-label="Search"
          />
        </form>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link"
              aria-current="page"
              :to="'/howdudeworks'"
              >How it works</router-link
            >
          </li>
          <li class="nav-item">
            <button
              class="nav-link"
              v-if="this.$store.state.auth.token"
              @click="logout()"
              style="border: none; background: none"
            >
              Logout
            </button>
            <router-link class="nav-link" :to="'/login'" v-else
              >Login</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/register'"
              >Sign Up</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="'/cart'"
              style="position: relative"
            >
              <img src="../../assets/img/shopping-cart.png" class="img-fluid" />
              <div class="cart-counter" v-if=" this.$store.state.cart.cartCount && this.$store.state.auth.token">
                {{ this.$store.state.cart.cartCount }}
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link art-button" :to="'/sellurart'">
              Sell Your Art
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  mounted() {
    console.log("Header Mounted");
    this.$store
      .dispatch("CHECK_LOGIN")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
      if(this.$store.state.auth.token){
      this.$store
      .dispatch("GET_CART_DEATILS")
      .then((success) => {
      })
      .catch((error) => {
        console.log(error.data);
      });
      }
  },

  methods: {
    logout() {
      this.$store
        .dispatch("LOGOUT")
        .then((success) => {
          if (success) {
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.cart-counter {
  background: red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: 20px;
  color: #fff;
  font-size: 10px;
  text-align: center;
}
@media (max-width: 990px) {
  .cart-counter {
    right: -10px;
  }
}
</style>
