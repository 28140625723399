import axios from "axios";
import config from "../../config";
import store from "../index";

export default {
  state: {
    categories: [],
  },
  getters: {},
  actions: {
    GET_CATEGORY: async ({ commit }) => {
      try {
        const categoryList = await axios.get(`categories`);
        commit("setCategories", categoryList);
        return categoryList;
      } catch (e) {
        Promise.reject(e);
      }
    },
  },
  mutations: {
    setCategories(state, data) {
      state.categories = data.data.data;
    },
  },
};
