<template>
  <div class="col-lg-3">
    <div class="card card-profile">
      <div class="card-body text-center d-block">
        <a href="artist-profile.html" class="profile-pic">
          <img src="../../assets/img/Ellipse-3.png" class="img-fluid" />
        </a>
        <div class="artist-pro">
          <h4 class="mt-3">Mahadi Rahman</h4>
          <p>UI Designer & WordPress Expart</p>
          <p><i class="fa fa-map-marker me-2"></i> Rochester, United Kingdom</p>
          <a href="#" class="art-button w-100 d-block text-center">Follow</a>
          <a href="#" class="art-button-o w-100 d-block text-center mt-2"
            >Message</a
          >
        </div>

        <div class="artist-data">
          <div class="row mt-4">
            <div class="col-lg-6 text-start">
              <p class="m-0">Project</p>
            </div>
            <div class="col-lg-6 text-end">
              <p class="m-0">38</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 text-start">
              <p class="m-0">Follower</p>
            </div>
            <div class="col-lg-6 text-end">
              <p class="m-0">1k</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 text-start">
              <p class="m-0">Following</p>
            </div>
            <div class="col-lg-6 text-end">
              <p class="m-0">2</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 text-start">
              <p class="m-0">Favorite</p>
            </div>
            <div class="col-lg-6 text-end">
              <p class="m-0">300k</p>
            </div>
          </div>
        </div>

        <div class="artist-footer">
          <span> ABOUT ME </span>
          <p>
            Graphic designer, and artist from Russia. I am combining the
            traditional with the modern, playing with fonts.
          </p>
          <span> ON THE WEB </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
