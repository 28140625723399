

<template>
<DudeWorkingComponent></DudeWorkingComponent>
  
</template>

<script>
import DudeWorkingComponent from "./DudeWorkingComponent.vue";

export default {
  name:'HowDudeWorkComponent',
  components:{
    DudeWorkingComponent
  }

};
</script>