<template>
   <section class="featured-artists-section py-2">
    <div class="container how-to-work-row mt-5 featured-artists mb-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="heading-1 pb-3 mb-4">How <span>DUDE</span> works</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 text-start">
          <img src="../assets/img/004-cloud-upload.png" class="img-fluid mb-3">
          <h4>Upload your design</h4>
          <p>Pulvinar turpis accumsan, at varius tortor. Aenean metus eu in arcu.</p>
        </div>
        <div class="col-lg-3 text-start">
          <img src="../assets/img/001-shining.png" class="img-fluid mb-3">
          <h4>Featuring your designs</h4>
          <p>Lacus eu, neque ipsum tristique vitae placerat purus aliquet. Massa arcu et aliquam eget elementum commodo.</p>
        </div>
        <div class="col-lg-3 text-start">
          <img src="../assets/img/004-cloud-upload.png" class="img-fluid mb-3">
          <h4>produced to order</h4>
          <p>Neque ullamcorper blandit risus ut lacus, scelerisque. Suspendisse imperdiet quis dictumst pretium.</p>
        </div>
        <div class="col-lg-3 text-start">
          <img src="../assets/img/003-money.png" class="img-fluid mb-3">
          <h4>Get Paid</h4>
          <p>Ut nam mauris tortor vel. Nam blandit tristique sit viverra sed tellus tempus. Scelerisque semper mauris volutpat.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "DudeWorkingComponent",
    }
</script>