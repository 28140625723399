<template>
  <section class="featured-design py-5">
    <div class="container">
      <div class="row h-100 d-flex justify-content-center align-items-center">
        <div class="col-lg-6">
          <h2 class="heading-1 pb-4">Categories</h2>
        </div>
        <div class="col-lg-6 text-end mb-4">
          <router-link class="art-button-o" :to="'/categories'"
            >Shop All</router-link
          >
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-sm col-6 m-2"
          v-for="category in categories"
          :key="category.id"
        >
          <CategoryCard :category="category"> </CategoryCard>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CategoryCard from "./CategoryCard.vue";

export default {
  components: {
    CategoryCard,
  },
  data() {
    return {
      categories: [],
    };
  },

  beforeMount() {
    this.$store
      .dispatch("GET_CATEGORY")
      .then((success) => {
        this.categories = success.data.data;
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
};
</script>
