<template>
  <section class="featured-design pt-5 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
          <div class="our-team-section text-center">
            <h2 class="heading-1 pb-0 mb-0">Meet our team</h2>
            <div class="our-team-description my-4">
              Our philosophy is simple — hire a team of diverse, passionate
              people and foster a culture that empowers you to do you best work.
            </div>
          </div>
        </div>
      </div>
      <div class="team-members row my-4">
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 4 -->
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-member-single col-lg-3 mb-5 about-card-team">
          <div class="card text-center pt-3 border-0">
            <div class="card-body">
              <a href="artist-profile.html" class="card-img-d">
                <img
                  class="team-member-profile-image img"
                  src="../../assets/img/team_member.svg"
                  alt="image not found"
                />
              </a>
              <p class="team-member-name">Olivia Rhye</p>
              <p class="team-member-desig">Founder & CEO</p>
              <p class="team-member-history">
                Former co-founder of Opendoor. Early staff at Spotify and
                Clearbit.
              </p>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-linkedin-square"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 8 -->
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
