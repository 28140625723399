<template>
  <section class="pb-5 pt-3 login-section">
    <div class="cover-image ">
      <div class="main-banner">
        <img
        :src="this.$store?.state?.auth.user.avatar_original"
        v-if="this.$store?.state?.auth.user.avatar_original"
        class="img-fluid"
      />
      <img src="../../assets/img/banner-profile.png" v-else class="img-fluid" />
      </div>
      <label class="upload-cover" for="upload-photo"
        ><img src="../../assets/img/camera-white.png" class="img-fluid" />
        Upload Cover</label
      >
      <input
        type="file"
        accept="image/*"
        @change="previewBanner"
        name="banner"
        id="upload-photo"
      />
    </div>
    <div class="container pb-5">
      <div
        class="row h-100 d-flex justify-content-center align-items-center sm-inline"
      >
        <div class="col-lg-5 text-center update-profile-card">
          <h2 class="heading-1 py-1">Update Profile</h2>

          <div class="row">
            <div class="col-lg-12 text-start mb-3">
              <div class="d-flex justify-content-center align-items-center">
                <img
                  v-if="this.$store?.state?.auth.user.avatar"
                  :src="this.$store?.state?.auth.user.avatar"
                  alt="img"
                  class="img-fluid w-25 rounded-circle border border-2"
                  :style="{ height: '100px' }"
                />
                <img
                  v-else
                  src="../../assets/img/bi_file-image.png"
                  alt="img"
                  class="img-fluid w-25 rounded-circle border border-2"
                  :style="{ height: '100px' }"
                />
                <label class="upload-label" for="upload-banner"
                  ><img
                    src="../../assets/img/camera-white.png"
                    class="img-fluid"
                /></label>
                <input
                  type="file"
                  accept="image/*"
                  @change="previewImage"
                  name="photo"
                  id="upload-banner"
                />
              </div>
            </div>
            <form
              action=""
              name="update_profile"
              class="login-form"
              method="post"
              @submit.prevent="getFormValues"
            >
              <div class="col-lg-12 text-start mb-3">
                <label for="username" class="mb-2">Username</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../../assets/img/avatar.png"
                    class="img-fluid ps-3"
                  />
                  <input
                    type="text"
                    v-model="user_name"
                    class="form-control"
                    placeholder="Enter Username"
                  />
                  <!-- <Field
                    name="username"
                    type="username"
                    class="form-control"
                    placeholder="Enter Username"
                  /> -->
                </div>
                <!-- <ErrorMessage name="email" /> -->
              </div>
              <div class="col-lg-12 text-start mb-3">
                <label for="email-address" class="mb-2">Email</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <img src="../../assets/img/mail.png" class="img-fluid ps-3" />
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    placeholder="Enter Email"
                  />
                  <!-- <Field
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Enter email "
                  /> -->
                </div>
                <!-- <ErrorMessage name="email" /> -->
              </div>
              <div class="col-lg-12 text-start mb-3">
                <label for="artist_type" class="mb-2">User Type</label>
                <div
                  class="input-div d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../../assets/img/check.png"
                    class="img-fluid ps-3"
                  />
                  <input
                    type="text"
                    v-model="artist_type"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <!-- <ErrorMessage name="password" /> -->
              </div>
              <div class="col-lg-12 text-start">
                <button
                  type="submit"
                  value="Submit"
                  class="art-button signin-btn d-block w-100 border-0"
                >
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      preview: this.$store?.state?.auth.user.avatar || null,
      image: null,
      bannerImage:null,
      artist_type: this.$store?.state?.auth.user.type,
      email: this.$store?.state?.auth.user.phone || null,
      user_name: this.$store?.state?.auth.user.name || null,
      password:null,
      bannerPreview:this.$store?.state?.auth.user.avatar_original || null
    };
  },
  mounted() {
    console.log("Header Mounted");
    this.$store
      .dispatch("GET_PROFILE")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
  },
  methods: {
    previewImage: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            console.log(image.width, image.height);
          };
          let img = e.target.result.substr(reader.result.indexOf(',') + 1);
          let formData={
            image :img,
            filename:input.files[0].name,
            type_img:"Profile",
          }
          this.$store
        .dispatch("UPLOAD_PROFILE_IMG", formData)
        .then((success) => {
          if (success.user) {
            // this.$store.dispatch("GETCARTITEMS");
          }
        })
        .catch((error) => {
          this.$router.push("/login");
        });
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewBanner: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            console.log(image.width, image.height);
          };
          let img = e.target.result.substr(reader.result.indexOf(',') + 1);;
          let formData={
            image :img,
            filename:input.files[0].name,
            type_img:"Banner"
          }
          this.$store
        .dispatch("UPLOAD_BANNER_IMG", formData)
        .then((success) => {
          if (success.user) {
            // this.$store.dispatch("GETCARTITEMS");
          }
        })
          this.bannerPreview = e.target.result;
        };
        this.bannerImage = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    getFormValues(submitEvent) {
      let formData = {
        email:this.email,
        password:this.password,
        artist_type:this.artist_type,
        user_name:this.user_name
      }
      this.$store
      .dispatch("UPDATE_PROFILE",formData)
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
    },
  },
};
</script>
<style scoped>
.cover-image button {
  position: relative;
  top: -5rem;
  float: right;
}
.update-profile-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 8px;
  border: none;
  padding: 20px 40px;
  margin: 20px 0px;
}
.upload-label {
  position: relative;
  left: -15px;
  top: 24px;
  background: #f95373;
  padding: 5px 7px 8px 9px;
  border-radius: 50%;
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
#upload-banner {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.upload-cover {
  position: relative;
  left: 88%;
  top: -60px;
  background: #f95373;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
}
.main-banner img{
    height: 500px !important;
    width: 100%;
  }
@media (max-width: 990px) {
  .sm-inline {
    display: inline !important;
  }
  .input-div img {
    display: none;
  }
  .upload-cover {
    left: 0;
  }
  .cover-image #upload-photo {
    opacity: 0;
    position: relative;
    z-index: -1;
  }
  .cover-image #upload-banner {
    opacity: 0;
    position: relative;
    z-index: -1;
  }
  .main-banner img {
    height: 225px !important;
    width: 100%;
  }
}
</style>
