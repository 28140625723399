import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import HomeComponent from '../views/HomeComponent.vue';
import LoginComponent from '../components/LoginComponent.vue';
import RegisterComponent from '../components/RegisterComponent.vue';
import SellYourArtComponent from '../components/SellYourArtComponent.vue';
import HowDudeWorkComponent from '../components/HowDudeWorkComponent.vue';
import DesignsComponent from '../components/DesignsComponent.vue';
import AboutUsComponent from '../components/AboutUsComponent.vue';
import ArtistComponent from '../components/Artists/ArtistComponent.vue';
import ProductComponent from '../components/Product/ProductListPage.vue';
import ProductDetailsComponent from '../components/Product/ProductDetailsPage.vue';
import CategoryComponent from '../components/Category/CategoryList.vue';
import FeaturedComponent from '../components/FeaturedProducts/FeaturedListComponent.vue';
import Cart from '../components/Cart/CartPage.vue';
import CheckOutPage from '../components/Cart/OrderCheckoutPage.vue';
import Address from '../components/Address/EditAddressPage.vue';
import ThankYouPage from '../components/Cart/ThankYouPage.vue';
import SellYourArtDeatils from '../views/SellYourArtDetails.vue';
import SellArtForm from '../components/Art/SellArtForm.vue';
import OrderComponent from '../components/Orders/OrdersListPage.vue';
import ProfileComponent from '../components/User/ProfileComponent.vue';
import addAddress from '../components/Address/AddAddressPage.vue';
import OrderDetailsPage from '../components/Orders/OrdersDetailPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent,
    beforeEnter: (from, to, next) => {
      if (!store.state.auth.token) next()
      else { next('/') }
    }
    
},
{
    path: "/register",
    name: "register",
    component: RegisterComponent,
    
},
{
    path: "/sellurart",
    name: "sellurart",
    component: SellYourArtComponent,
    
},
{
    path: "/howdudeworks",
    name: "howdudeworks",
    component: HowDudeWorkComponent,
    beforeEnter: (from, to, next) => {
        if (store.state.auth.token) next()
        else { next('/login') }
      }
    
},
{
    path: "/products",
    name: "products",
    component: ProductComponent,
    
},
{
    path: "/order",
    name: "order",
    component: OrderComponent,
    
},
{
    path: "/aboutus",
    name: "aboutus",
    component: AboutUsComponent,
    
},
{
    path: "/update_profile",
    name: "Update Profile",
    component: ProfileComponent,
    
},
{
  path: '/design',
  name: 'Design',
  component: DesignsComponent
},
{
    path: "/artists",
    name: "artists",
    component: ArtistComponent,
    
},
{
    path: "/feature-products",
    name: "featureProducts",
    component: FeaturedComponent,
    
},
{
  path: '/categories',
  name: 'Category',
  component: CategoryComponent
},
{
  path: '/product-details/:id',
  name: 'Product Details',
  component: ProductDetailsComponent,
  props: true,
},
{
  path: '/cart',
  name: 'Cart',
  component: Cart,
},
{
  path: '/address',
  name: 'Address',
  component: addAddress,
},
{
  path: '/check-out',
  name: 'CheckOutPage',
  component: CheckOutPage,
},
{
  path: '/thankyou',
  name: 'ThankYouPage',
  component: ThankYouPage,
},
{
  path: '/order-details/:id',
  name: 'OrderDetailsPage',
  component: OrderDetailsPage,
  props: true,
},
{
  path: '/sell-your-art',
  name: 'Sell Art Detail',
  component: SellYourArtDeatils,
  beforeEnter: (from, to, next) => {
    if (store.state.auth.user_type === "Artist") {
      next()
    }
    else { next('/login') }
  }
},
{
  path: '/sell-art-form',
  name: 'Sell Art Form',
  component: SellArtForm,
  beforeEnter: (from, to, next) => {
    if (store.state.auth.user_type === "Artist") next()
    else { next('/login') }
  }
},
]


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
  linkActiveClass: 'active',
  mode: "history",
  root:  '/dudejustdude'
});

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })

// router.beforeEach(async (to, from, next) => {
//   const user = store.state.User.user;

//   if (!user) {
//     await store.dispatch('User/setUser', users[0]);
//   }

//   const isAdmin = false;
//   const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

//   if (requiresAdmin && !isAdmin) next({ name: 'Home' });
//   else next();
// })

export default router
