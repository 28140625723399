<template>
  <section class="py-5">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-6 text-center">
          <div class="card rounded mb-3">
            <div class="card-body py-5">
              <h2 class="heading-1 pb-2">Thankyou</h2>
              <p class="mb-5">Your order was completed successfully</p>

              <router-link class="text-success" :to="'/'">Back to Home</router-link><br /><br />
              <router-link class="text-success" :to="'/order'">Go to Orders</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
