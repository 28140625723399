<template>
          <a class="card-box" href="#">
            <img class="img-fluid rounded" v-bind:src="product.thumbnail_image">
            <div class="py-3">
              <h6 class="card-h-1">{{ product.name }}</h6>
              <h6 class="card-h-2">{{ product.has_discount }}</h6>
              <h6 class="card-h-3">{{ product.main_price }}</h6>
            </div>
          </a>

        

</template>

<script>

export default {
    props: ['product'],
}
</script>
