import axios from "axios";
import config from "../../config";
import store from "../index";

export default {
  state: {
    token: "",
    user: null,
    user_type:"",
  },
  getters: {
    // IS_AUTH_TOKEN (state) {
    //     let token = localStorage.getItem('token');
    //   }
  },
  actions: {
    LOGIN: async ({ commit }, payload) => {
      try {
        let response = await axios.post(`auth/login`, payload);
        if (response.status == 200) {
          store.dispatch("ATTEMPT", response.data);
        }
        return response.data;
      } catch (error) {
        return error.response;
      }
    },
    REGISTER: async ({ commit }, payload) => {
      try {
        let response = await axios.post(`auth/signup`, payload);
        // store.dispatch('ATTEMPT', response.data);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    LOGOUT: async ({ commit }) => {
      localStorage.removeItem("token");
      localStorage.removeItem("token_type");
      localStorage.removeItem("user");
      commit("REMOVE_TOKEN");
      return true;
    },
    CHECK_LOGIN: async ({ commit }) => {
      let value = localStorage.getItem("token");
      let uservalue = localStorage.getItem("user");
      if (value && JSON.parse(uservalue)) {
        commit("SET_USER_TOKEN", value);
        commit("SET_USER_DATA", uservalue);
      } else {
        return false;
      }
    },
    GET_PROFILE: async ({ commit }) => {
      try {
        let response = await axios.get(`user/info`);
        if (response.status == 200) {
          commit("SET_USER", response.data.data[0]);
        }
        // store.dispatch('ATTEMPT', response.data);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    
    UPLOAD_PROFILE_IMG: async ({ commit },formData) => {
      try {
        const uploadProfileImg = await axios.post(`profile/update-image`,formData);
        console.log(uploadProfileImg.data.path);
        commit("SET_PROFILE_IMG",uploadProfileImg)
        return uploadProfileImg;
      } catch (e) {
        Promise.reject(e);
      }
    },
    UPLOAD_BANNER_IMG: async ({ commit },formData) => {
      try {
        const uploadBannerImg = await axios.post(`file/image-upload`,formData);
        console.log(uploadBannerImg.data.path);
        commit("SET_BANNER_IMG",uploadBannerImg);
        return uploadBannerImg;
      } catch (e) {
        Promise.reject(e);
      }
    },
    UPDATE_PROFILE: async ({ commit },formData) => {
      try {
        const uploadProfileData = await axios.post(`profile/update`,formData);
        return uploadProfileData;
      } catch (e) {
        Promise.reject(e);
      }
    },

    async ATTEMPT({ commit }, data) {
      commit("SET_TOKEN", data);
      try {
        // const response = await axios.post('auth/info', payload);
        commit("SET_USER", data.user);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data.access_token;
      localStorage.setItem("token", data.access_token);
      localStorage.setItem("token_type", data.token_type);
    },
    SET_USER(state, data) {
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    REMOVE_TOKEN(state) {
      state.user = null;
      state.token = null;
    },
    SET_USER_TOKEN(state, data) {
      state.token = data;
    },
    SET_USER_DATA(state, data) {
      let dataa = JSON.parse(data)
      state.user = dataa;
      state.user_type = dataa?.type
    },
    SET_PROFILE_IMG(state, data) {
      state.user.avatar = data.data.path;
      localStorage.setItem("user", JSON.stringify(state.user));
      // state.user_type = dataa?.type
    },
    SET_BANNER_IMG(state, data) {
      state.user.avatar_original = data.data.path;
      console.log(state.user.avatar_original);
      localStorage.setItem("user", JSON.stringify(state.user));
      // state.user_type = dataa?.type
    },
  },
};
