import Vuex from "vuex";

//modules
// import user from "./modules/user"
import Auth from "./modules/auth";
import Products from "./modules/products";
import Category from "./modules/category";
import Cart from "./modules/cart";
import Artist from "./modules/artist";
import Address from "./modules/address";
import Orders from "./modules/order"
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

const toast = (data) => {
  createToast(data);
};
export default new Vuex.Store({
  state: {
    showLoading: false,
    loginTest: false,
  },
  actions: {
    createEvent({ commit }, event) {
      commit("ADD_EVENT", event);
    },
    TOAST: async ({ commit }, data) => {
      toast(data);
    },
  },
  mutations: {
    ADD_EVENT(state, event) {
      state.showLoading = event;
      state.loginTest = localStorage.getItem("token") ? true : false;
    },
  },
  modules: {
    auth: Auth,
    products: Products,
    category: Category,
    cart: Cart,
    artist:Artist,
    address:Address,
    order:Orders
  },
});
