import axios from "axios";
import { array } from "yup";
import config from "../../config";
import store from "../index";

export default {
  state: {
    orderList:[],
  },
  getters: {
  },
  actions: {
    GET_ORDER_DEATILS: async ({ commit }) => {
      try {
        const GetOrderDetails = await axios.get(`orders/details/list`);
        commit("setOrderItems", GetOrderDetails);
        return GetOrderDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
  },
  mutations: {
    setOrderItems(state, data) {
      let array1 = [];
      let orderData = data.data.data;
      for(let i=0; i<orderData.length;i++){
        let orderList = orderData[i]?.product_id;
        for(let j=0;j<orderList?.length;j++){
          array1.push(orderList[j]);
        }
      }
      this.orderList = array1;
    },
  },
};
