/* eslint-disable */ 
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";

import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/js/bootstrap.bundle.min.js";
import "./assets/js/jquery-3.6.0.min.js";
require("./bootstrap");

window.Vue = require("vue").default;
createApp(App).use(store).use(router).mount("#app");
