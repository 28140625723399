<template>
  <section class="featured-design py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="heading-1 pb-4">All Designs</h2>
        </div>
      </div>
      <!-- <div class="row">
        <div
          class="col-lg-3 col-sm col-6"
          v-for="product in products.products"
          :key="product.id"
        >
          <a class="card-box" href="#">
            <img
              class="img-fluid rounded"
              v-bind:src="product.thumbnail_image"
            />
            <div class="py-3">
              <h6 class="card-h-1">{{ product.name }}</h6>
              <h6 class="card-h-2">{{ product.has_discount }}</h6>
              <h6 class="card-h-3">{{ product.main_price }}</h6>
            </div>
          </a>
        </div>

        <div class="col-lg-12 text-center mt-4">
          <a href="#" class="art-button-o">Load More</a>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
export default {};
</script>
