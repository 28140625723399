<template>
  <loader v-if="$store.state.showLoading" />
  <HeaderComponent />
  <router-view ></router-view>
  <FooterComponent />
</template>

<script>
import HeaderComponent from "./components/common/HeaderComponent.vue";
import FooterComponent from "./components/common/FooterComponent.vue";
import loader from "./components/common/loader.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    loader,
  },
};
</script>
