<template>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-lg-12">
      <h2 class="heading-1 pb-4">Delivery Address</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="px-5">
          <form
            action=""
            class="login-form"
            method="post"
            @submit.prevent="getFormValues"
          >
            <div class="others-in">
              <div class="row mt-4">
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">Name</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <input
                      class="form-control"
                      type="text"
                      v-model="name"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">10-digit mobile number</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <input
                      v-model="phone"
                      class="form-control"
                      type="number"
                      maxlength="10"
                      required
                    />
                  </div>
                </div>

                <div class="col-lg-12 text-start mb-3">
                  <label class="mb-2">Address (Area and Street)</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <textarea
                      v-model="address"
                      class="form-control"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                </div>

                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">Landmark (Optional)</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <input
                      v-model="landmark"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">Alternate Phone (Optional)</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <input
                      v-model="alternate_number"
                      class="form-control"
                      type="number"
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">Country</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <select
                      name="country_id"
                      class="form-select form-control"
                      @change="onChange($event)"
                      v-model="country_id"
                      required
                    >
                      <option value="">Select Country</option>
                      <option
                        v-bind:value="list.id"
                        v-for="list in countries"
                        :key="list.id"
                      >
                        {{ list.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">State</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <select
                      name="state"
                      class="form-select form-control"
                      @change="onChangeState($event)"
                      v-model="state_id"
                      required
                    >
                      <option value="">Select Satate</option>
                      <option
                        :value="list.id"
                        v-for="list in states"
                        :key="list.id"
                      >
                        {{ list.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">City/District/Town</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <select
                      name="city"
                      class="form-select form-control"
                      v-model="city_id"
                      required
                    >
                      <option value="">Select City</option>
                      <option
                        :value="list.id"
                        v-for="list in cities"
                        :key="list.id"
                      >
                        {{ list.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6 text-start mb-3">
                  <label class="mb-2">Pincode</label>
                  <div
                    class="input-div d-flex justify-content-center align-items-center"
                  >
                    <input
                      class="form-control"
                      type="number"
                      maxlength="6"
                      v-model="postal_code"
                      required
                    />
                  </div>
                </div>

                <div class="col-lg-12 text-start mb-3">
                  <label class="mb-2">Address Type</label>

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          v-model="address_type"
                          value="Home"
                          required
                        />
                        <label class="form-check-label" for="flexRadioDefault3">
                          Home (All day Delivery)
                        </label>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          value="Office"
                          v-model="address_type"
                          required
                        />
                        <label class="form-check-label" for="flexRadioDefault4">
                          Work (Delivery between 10 AM -5 PM)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 mb-4">
              <div></div>
              <div class="col-lg-5">
                <button
                  class="art-button w-100 text-center mt-2 border-0"
                >
                  Save Address
                </button>
              </div>
              <div class="col-lg-3">
                <button
                  class="art-button w-100 text-center mt-2 border-0"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: [],
      cities: [],
      states: [],
      name: "",
      phone: "",
      address: "",
      landmark: "",
      alternate_number: "",
      country_id: "",
      state_id: "",
      city_id: "",
      postal_code: "",
      address_type: "",
      selectedValue: null,
    };
  },
  mounted() {
    this.$store
      .dispatch("GET_COUNTRY_DETAILS")
      .then((success) => {
        this.countries = success.data.data;
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
  methods: {
    onChange(event) {
      let country_id = event.target.value;
      if (country_id) {
        this.$store
          .dispatch("GET_STATE_DETAILS", country_id)
          .then((success) => {
            this.states = success.data.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      } else {
        this.states = [];
        this.cities = [];
      }
    },
    onChangeState(event) {
      let state_id = event.target.value;
      if (state_id) {
        this.$store
          .dispatch("GET_CITY_DETAILS", state_id)
          .then((success) => {
            this.cities = success.data.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      } else {
        this.cities = [];
      }
    },

    getFormValues(submitEvent) {
      let formData = {
        name: this.name,
        phone: this.phone,
        address: this.address,
        address_type: this.address_type,
        country_id: this.country_id,
        state_id: this.state_id,
        city_id: this.city_id,
        alternate_number: this.alternate_number,
        postal_code: this.postal_code,
        landmark: this.landmark,
      };
      this.$store
        .dispatch("ADD_ADDRESS", formData)
        .then((success) => {
          if (success.status == 200) {
            this.$store.dispatch("GET_ADDRESS_DETAILS");
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    getProfileDetails(id) {
      this.$store
        .dispatch("GET_ADDRESS_DETAILS_BY_ID", id)
        .then((success) => {
          this.address = success.data.data[0];
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
  },
};
</script>
