<template>
  <a class="card-box img-box" href="#">
    <img class="" v-bind:src="category.banner" />
    <!-- <img src="../../assets/img/pro-1.png" alt=""> -->

    <div class="img-content">
      <button class="card-h-1 rounded">{{ category.name }}</button>
    </div>
  </a>
</template>

<script>
export default {
  props: ["category"],
};
</script>
<style scoped>
.img-box {
  width: 100%;
  height: auto;
  position: relative;
}
.img-content {
  position: absolute;
  top: 90px;
  left: 28%;
}
.img-content button {
  padding: 10px;
  color: #fff;
  background: linear-gradient(268.63deg, #efb933 0%, #f95373 51.17%);
  box-shadow: 0px 8px 16px rgb(249 83 115 / 20%);
  border: none;
}
</style>
