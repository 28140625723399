<template>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="heading-1 pb-4 text-center">Product Preview</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm col-6 mb-4">
          <div class="card card-pro-reveiw">
            <div class="card-body">
              <a href="#">
                <img class="img-fluid w-100" src="../../assets/img/pro-1.png" />
                <div class="py-3 text-center">
                  <h6 class="card-h-1">Standard Print Clothing</h6>
                  <h6 class="card-h-2">Standard Print Clothing</h6>
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-lg-4">
                      <a class="btn btn-sm btn-outline-dark w-100 mt-3"
                        ><i class="fa fa-pencil"></i> Edit</a
                      >
                    </div>
                    <div class="col-lg-4">
                      <a class="btn btn-sm btn-outline-danger w-100 mt-3"
                        ><i class="fa fa-trash"></i> Delete</a
                      >
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm col-6 mb-4">
          <div class="card card-pro-reveiw">
            <div class="card-body">
              <a href="#">
                <img class="img-fluid w-100" src="../../assets/img/pro-1.png" />
                <div class="py-3 text-center">
                  <h6 class="card-h-1">Standard Print Clothing</h6>
                  <h6 class="card-h-2">Standard Print Clothing</h6>
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-lg-4">
                      <a class="btn btn-sm btn-outline-dark w-100 mt-3"
                        ><i class="fa fa-pencil"></i> Edit</a
                      >
                    </div>
                    <div class="col-lg-4">
                      <a class="btn btn-sm btn-outline-danger w-100 mt-3"
                        ><i class="fa fa-trash"></i> Delete</a
                      >
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm col-6 mb-4">
          <div class="card card-pro-reveiw">
            <div class="card-body">
              <a href="#">
                <img class="img-fluid w-100" src="../../assets/img/pro-1.png" />
                <div class="py-3 text-center">
                  <h6 class="card-h-1">Standard Print Clothing</h6>
                  <h6 class="card-h-2">Standard Print Clothing</h6>
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-lg-4">
                      <a class="btn btn-sm btn-outline-dark w-100 mt-3"
                        ><i class="fa fa-pencil"></i> Edit</a
                      >
                    </div>
                    <div class="col-lg-4">
                      <a class="btn btn-sm btn-outline-danger w-100 mt-3"
                        ><i class="fa fa-trash"></i> Delete</a
                      >
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {
    
}
</script>
