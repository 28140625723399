<template>
  <section class="featured-design py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="heading-1 pb-4">Featured Products</h2>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-sm col-6"
          v-for="product in this.featuredProducts"
          :key="product.id"
        >
          <FeaturedCardPage :product="product"></FeaturedCardPage>
        </div>
      </div>
      <div class="col-lg-12 text-center mt-4">
        <router-link class="art-button-o" :to="'/feature-products'"
          >Load More</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import FeaturedCardPage from "./FeaturedCardComponent.vue";

export default {
  components: {
    FeaturedCardPage,
  },
  data() {
    return {
      featuredProducts: [],
    };
  },

  beforeMount() {
    this.$store
      .dispatch("GET_FEATURED_PRODUCTS")
      .then((success) => {
        this.featuredProducts = success.data.data;
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
};
</script>
