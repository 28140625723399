<template>
    <div class="card">
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" aria-current="true">
            Price
            <span class="text-warnning"
              >({{ this.$store.state.cart.cartCount }} items)</span
            >
            <span class="pull-right">{{
              this.$store.state.cart.cartSummary.sub_total
            }}</span>
          </li>
          <li class="list-group-item">
            Discount
            <span class="pull-right text-success"
              >− {{ this.$store.state.cart.cartSummary.discount }}</span
            >
          </li>
          <li class="list-group-item">
            Delivery Charges
            <span class="pull-right text-success">{{
              this.$store.state.cart.cartSummary.shipping_cost
            }}</span>
          </li>
          <li class="list-group-item">
            <b>Total Amount</b>
            <span class="pull-right fw-bold">{{
              Number(this.$store.state.cart.cartSummary.grand_total_value).toFixed(2)
            }}</span>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  components:{
  }
  
};
</script>
