<template>
  <section class="py-5">
    <div class="container">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-lg-12">
            <h2 class="heading-1 pb-4">Checkout Page</h2>
          </div>
        </div>
      <div v-if="this.$store.state.cart.cartItems.length > 0">
        <div class="row">
          <div class="col-lg-7">
            <CartItemsPage />
            <div class="row">
              <div class="col-lg-5">
                <button
                  type="submit"
                  class="art-button d-block border-0 text-center mt-2"
                  @click="submit()"
                >
                  CONFIRM ORDER
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <CartSummaryPage />
            <AddressBox />
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-else>
        <div class="col-lg-8">
          <div class="py-5 cart-section">
            <img src="../../assets/img/cart-empty.jpg" alt="img" class="w-25" />
            <h5>Your cart is empty</h5>
            <p>Add items to it now</p>
            <router-link class="art-button border-0" to="/products"
              >Shop Now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CartSummaryPage from "./CartSummary.vue";
import CartItemsPage from "./CartItemsPage.vue";
import AddressPage from "../Address/EditAddressPage.vue";
import AddressListPage from "../Address/AddressList.vue";
import AddressBox from "./AddressBox.vue";
import { createToast } from "mosha-vue-toastify";

export default {
  components: {
    AddressPage,
    AddressListPage,
    CartSummaryPage,
    CartItemsPage,
    AddressBox,
  },
  mounted() {
    this.$store
      .dispatch("GET_CART_DEATILS")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
    this.$store
      .dispatch("GET_CART_SUMMARY")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
  },
  methods: {
    submit() {
      let formData = {
        address_id: this.$store.state.address.addressIdForCart,
      };
      if (formData.address_id) {
        this.$store
          .dispatch("CONFIRM_ORDER", formData)
          .then((success) => {
            if(success.status == 200){
              this.$store.dispatch("GET_CART_DEATILS");
              this.$store.dispatch("GET_CART_SUMMARY");
              this.$router.push("/thankyou");
            }
          })
          .catch((error) => {
            console.log(error.data);
          });
      } else {
        createToast("Please Select Address");
      }
    },
  },
};
</script>
