<template>
  <section class="featured-design py-2">
    <div class="container-fluid mt-5 featured-artists mb-5 pe-0">
      <div class="row h-100 d-flex justify-content-center align-items-center">
        <div class="col-lg-6 mb-4">
          <div class="p-5">
            <h2 class="heading-1 pb-3">
              Creativity. Community. And sweet, sweet cash.
            </h2>
            <p>
              Dis pellentesque augue enim mi rhoncus mattis malesuada. Cras in
              est lectus amet, bibendum in sed tortor. Vel volutpat amet
              imperdiet elementum risus diam senectus nisl orci. In turpis amet
              convallis urna, urna curabitur.
            </p>
            <router-link
              class="art-button signin-btn border-0 my-4"
              :to="'/sell-art-form'"
              >Start Selling</router-link
            >
          </div>
        </div>
        <div class="col-lg-6 pe-0 mb-4">
          <img src="../assets/img/sell-3.png" class="img-fluid w-100" />
        </div>
      </div>
    </div>
  </section>
  <section class="py-2">
    <div class="container-fluid mt-5 featured-artists mb-5">
      <div class="row h-100 d-flex justify-content-center align-items-center">
        <div class="col-lg-5 mb-4">
          <img src="../assets/img/sell-2.png" class="img-fluid w-100" />
        </div>
        <div class="col-lg-5 mb-4">
          <h2 class="heading-1 pb-3">Find your fans</h2>
          <p>
            Egestas purus erat suspendisse malesuada. Penatibus id imperdiet
            commodo non eu dignissim. Magnis consectetur tellus rhoncus, gravida
            leo nunc felis. Dolor, sagittis, augue proin nunc. Mauris faucibus
            ornare proin placerat euismod vel.
          </p>
          <p>
            At maecenas turpis id commodo maecenas. Mauris morbi nunc dignissim
            vel tellus condimentum vel, mauris. Vulputate lectus dui dignissim
            lacus adipiscing imperdiet duis. Tristique orci, ornare purus
            aliquet vitae etiam. Facilisi orci.
          </p>
        </div>
      </div>
    </div>
  </section>
  <DudeWorkingComponent></DudeWorkingComponent>
  <section class="py-2">
    <div class="container-fluid mt-5 featured-artists mb-5">
      <div class="row h-100 d-flex justify-content-center align-items-center">
        <div class="col-lg-5 mb-4">
          <h2 class="heading-1 pb-3">Your art on over 70 unique products</h2>
          <p>
            Egestas purus erat suspendisse malesuada. Penatibus id imperdiet
            commodo non eu dignissim. Magnis consectetur tellus rhoncus, gravida
            leo nunc felis. Dolor, sagittis, augue proin nunc. Mauris faucibus
            ornare proin placerat euismod vel.
          </p>
          <p>
            At maecenas turpis id commodo maecenas. Mauris morbi nunc dignissim
            vel tellus condimentum vel, mauris. Vulputate lectus dui dignissim
            lacus adipiscing imperdiet duis. Tristique orci, ornare purus
            aliquet vitae etiam. Facilisi orci.
          </p>
          <router-link class="signin-btn-o mt-2" :to="'/sell-art-form'"
            >Start Selling</router-link
          >
        </div>
        <div class="col-lg-5 mb-4">
          <img src="../assets/img/sell-1.png" class="img-fluid w-100" />
        </div>
      </div>
    </div>
  </section>
  <ArtistsComponent></ArtistsComponent>
</template>

<script>
import DudeWorkingComponent from "./DudeWorkingComponent.vue";
import ArtistsComponent from "./ArtistsComponent.vue";

export default {
  name: "SellYourArtComponent",
  components: {
    DudeWorkingComponent,
    ArtistsComponent,
  },
};
</script>
