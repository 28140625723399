<template>
    <div class="container footer py-5">
    <div class="row footer-row-1 h-100 d-flex justify-content-center align-items-center">
      <div class="col-lg-4 text-center">
        <h4>Newsletter</h4>
        <p>Be the first one to know about discounts, offers and events weekly in your mailbox. Unsubscribe whenever you
          like with one click.</p>
      </div>
    </div>

    <div class="row footer-row-2 h-100 d-flex justify-content-center align-items-center">
      <div class="col-lg-6 text-center">
        <div class="subscription">
          <img src="../../assets/img/sms.png" class="img-fluid">
          <input class="form-control border-0" placeholder="Enter your email">
          <button class="art-button">Submit</button>
        </div>
      </div>
    </div>

    <div class="row footer-row-3 h-100 d-flex justify-content-center align-items-center mt-5">
      <div class="col-lg-4 col-sm col-6 text-center">
        <img src="../../assets/img/dude-logo.png" class="img-fluid  mx-auto">
      </div>
      <div class="col-lg-4 col-sm col-6 d-flex justify-content-center align-items-center footer-img">
        <div class="insta">
          <img src="../../assets/img/insta-vector.png" class="img-fluid">
        </div>
        <div class="tiktok ms-3">
          <img src="../../assets/img/tiktok-vector.png" class="img-fluid">
        </div>
      </div>
      <div class="col-lg-4 text-center">
        <ul class="list-unstyled list-inline pt-4">
          <li class="list-inline-item"><router-link :to="'/aboutus'">About Us </router-link></li>
          <li class="list-inline-item"><a href="#">Contact </a></li>
          <li class="list-inline-item"><a href="#">Privacy </a></li>
          <li class="list-inline-item"><a href="#">Terms & Condition </a></li>
        </ul>
      </div>
    </div>

  <button class="btnn" @click="scrollBehavior()"><i class="fa fa-arrow-up text-white"></i></button>

  </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Footer mounted.')
        },
        methods: {
    scrollBehavior() {
      window.scrollTo(0, 0);
    },
  },
    }
</script>
<style scoped>
.btnn{
  position: fixed;
    right: 18px;
    bottom: 6%;
    border: none;
    background: #21201e;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
}
</style>