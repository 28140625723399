<template>
  <section class="py-5">
    <div class="container">
      <!-- <div class="card-body">
              <div class="row">
                <div class="col-lg-1">
                  <img class="img-fluid" src="../../assets/img/p-1.png" />
                </div>
                <div class="col-lg-4 border-end">
                  
                    <h5 class="fw-bold ">Product Details</h5>
                  <div class="d-flex justify-content-between">
                     <div>
                        <h6 class="mb-0"><strong>Order name</strong></h6>
                        <p class="card-h-3 mb-0">Seller: seller name</p>
                   </div>
                    <div>
                        <h6 class="card-h-3"><strong>Total: </strong>$400.00</h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 border-end">
                    <h5 class="fw-bold">Order Details</h5>
                  <p class="card-h-3 mb-0">Order ID: 12345678</p>
                  <p class="card-h-3 mb-0">Contact: 9876543211</p>
                  <p class="card-h-3 mb-0">Address: hno. 404 street abc,<br>  colony XYZ , 120001</p>
                </div>
                <div class="col-lg-3">
                    <h5 class="fw-bold">Status</h5>
                  <h6 class="text-success mb-0">Delivered: 12 July 2022</h6>
                  <p class="card-h-3 mb-0">Order Shipped: 03 July 2022</p>
                  <p class="card-h-3 mb-0">Order placed: 02 July 2022</p>
                </div>
              </div>
            </div> -->
      <div class="row">
        <ul class="list-inline mb-0 text-muted">
          <li class="list-inline-item">
            Order Details <i class="fa fa-chevron-right text-muted font-xs"></i>
          </li>
          <li class="list-inline-item">Order ID: 094565</li>
        </ul>
        <div class="col-lg-7">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <img class="img-fluid" src="../../assets/img/p-1.png" />
                </div>
                <div class="col-lg-8">
                  <div>
                    <h4 class="card-h-1 fw-bold">Product Name</h4>
                    <h6 class="card-h-3 fw-bold">Price/Unit:-</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="fw-bold">Shipping Details</h5>
              <p class="mb-0">
                <strong>Address :</strong> xyz abc, bhopal 100001
              </p>
              <p class="mb-0"><strong>Contact :</strong> +91 987564534</p>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" aria-current="true">
                  Price
                  <span class="text-warnning"></span>
                  <span class="pull-right"></span>
                </li>
                <li class="list-group-item">
                  Discount
                  <span class="pull-right text-success"></span>
                </li>
                <li class="list-group-item">
                  Delivery Charges
                  <span class="pull-right text-success"></span>
                </li>
                <li class="list-group-item">
                  <b>Total Amount</b>
                  <span class="pull-right fw-bold"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
  },
  mounted() {
    this.$store
      .dispatch("GET_ORDER_DEATILS")
      .then((success) => {
        let array = [];
        if (success.status == 200) {
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
};
</script>
<style scoped>
.font-xs {
  font-size: 12px;
}
</style>
