<template>
  <carousel :items-to-show="5" :wrapAround="true">
    <slide v-for="slide in 10" :key="slide">
      <div class="item">
        <router-link class="card p-0 card-pro mb-3" :to="'/artists'">
          <div class="card-body p-0 text-center">
            <img
              src="../../assets/img/Rectangle-1.png"
              class="img-fluid profile-banner"
            />
            <div class="profile-pic">
              <img src="../../assets/img/Ellipse-1.png" class="img-fluid" />
            </div>
            <h4>Featured Designs</h4>
            <h5>View Shop</h5>
          </div>
        </router-link>
      </div>
    </slide>

    <template #addons>
      <navigation />
      <!-- <pagination /> -->
    </template>
  </carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
