import axios from "axios";
import config from "../../config";
import store from "../index";

export default {
  state: {
    categories: [],
    cartItems: [],
    cartCount: 0,
    cartSummary:{}
  },
  getters: {
    UPDATE_CART (state) {
       state.cartItems,
       state.cartCount,
       state.cartSummary
      }
  },
  actions: {
    GET_CART_DEATILS: async ({ commit }) => {
      try {
        const GetCartDetails = await axios.get(`cart-details`);
        commit("setCartItems", GetCartDetails);
        return GetCartDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_CART_SUMMARY: async ({ commit }) => {
      try {
        const GetCartSummary = await axios.get(`cart-summary`);
        commit("setCartSummary", GetCartSummary);
        return GetCartSummary;
      } catch (e) {
        Promise.reject(e);
      }
    },
    ADDTOCART: async ({ commit }, formData) => {
      try {
        const addToCart = await axios.post(`carts/add`, formData);
        store.dispatch('GET_CART_DEATILS');
        return addToCart;
      } catch (e) {
        Promise.reject(e);
      }
    },
    UPDATECARTQUANTITY: async ({ commit }, formData) => {
      try {
        const updatCart = await axios.post(`carts/change-quantity`, formData);
        return updatCart;
      } catch (e) {
        Promise.reject(e);
      }
    },
    DELETECARTITEM: async ({ commit }, cart_id) => {
      let formData ={
        cart_id: cart_id,
      }
      try {
        const deleteCartItem = await axios.post(`carts/delete`,formData );
        
        return deleteCartItem;
      } catch (e) {
        Promise.reject(e);
      }
    },
  },
  mutations: {
    setCartItems(state, data) {
      state.cartItems = data.data.data;
      state.cartCount = data.data.data.length;
    },
    setCartSummary(state, data) {
      state.cartSummary = data.data;
    },
  },
};
