import axios from "axios";
import config from "../../config";
import store from "../index";

export default {
  state: {
    products: [],
    featuredProduct: [],
    productDetails: {},
  },
  getters: {},
  actions: {
    GET_PRODUCTS: async ({ commit }) => {
      try {
        const products = await axios.get(`products`);
        commit("setProducts", products);
        return products;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_FEATURED_PRODUCTS: async ({ commit }) => {
      try {
        const featuredProducts = await axios.get(`products/featured`);
        commit("setFeaturedProducts", featuredProducts);
        return featuredProducts;
      } catch (e) {
        Promise.reject(e);
      }
    },
    GET_PRODUCTS_DEATILS: async ({ commit }, id) => {
      try {
        const productDetails = await axios.get(`products/${id}`);
        commit("productDetails", productDetails);
        return productDetails;
      } catch (e) {
        Promise.reject(e);
      }
    },
  },
  mutations: {
    setProducts(state, data) {
      state.products = data.data.data;
    },
    setFeaturedProducts(state, data) {
      state.featuredProduct = data.data.data;
    },
    productDetails(state, data) {
      state.productDetails = data.data.data;
    },
  },
};
