<template>
  <div class="container mt-5 featured-artists mb-5 py-2">
    <div class="row">
      <div class="col-lg-4">
        <h2 class="heading-1 pb-3">Featured Artists</h2>
        <p>
          Vitae vel sit nam iaculis auctor libero nisl. Dui a diam nam laoreet
          sed. At placerat lectus ultrices ut maecenas. Fermentum e
        </p>
      </div>
      <div class="col-lg-8">
        <div id="carousel04" class="owl-carousel owl-theme">
          <ArtistSubComponentVue></ArtistSubComponentVue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArtistSubComponentVue from "./Artists/ArtistSubComponent.vue";

export default {
  components: {
    ArtistSubComponentVue,
  },
};
</script>
