<template>
  <section class="py-5">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-12">
          <h2 class="heading-1 pb-4">My Cart</h2>
        </div>
      </div>
      <div class="row" v-if="this.$store.state.cart.cartItems.length > 0">
        <div class="col-lg-7">
          <CartItemsPage />
          <div class="row">
            <div class="col-lg-5">
              <router-link
                class="art-button d-block text-center mt-2"
                to="/check-out"
                >PLACE ORDER</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <CartSummaryPage />
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-else>
        <div class="col-lg-8">
          <div class="py-5 cart-section">
            <img src="../../assets/img/cart-empty.jpg" alt="img" class="w-25" />
            <h5>Your cart is empty</h5>
            <p>Add items to it now</p>
            <router-link class="art-button border-0" to="/products">Shop Now</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CartSummaryPage from "./CartSummary.vue";
import CartItemsPage from "./CartItemsPage.vue";
export default {
  components: {
    CartSummaryPage,
    CartItemsPage,
  },
  data() {
    return {
      cartCount: 0,
      cartItems: [],
      totalPrice: 0,
      shippingCharges: 0,
      cartSummary: {},
    };
  },
  mounted() {
    this.$store
      .dispatch("GET_CART_DEATILS")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
    this.$store
      .dispatch("GET_CART_SUMMARY")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
  },
  methods: {
    deleteCartItem(cart_id) {
      this.$store
        .dispatch("DELETECARTITEM", cart_id)
        .then((success) => {
          if (success) {
            this.$store
              .dispatch("GET_CART_DEATILS")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
            this.$store
              .dispatch("GET_CART_SUMMARY")
              .then((success) => {})
              .catch((error) => {
                console.log(error.data);
              });
          }
        })
        .catch((error) => {});
    },
    increaseCart() {
      if (this.items < 5) {
        this.items = this.items + 1;
      }
    },
    minusCart() {
      if (this.items > 1) {
        this.items = this.items - 1;
      }
    },
  },
};
</script>
