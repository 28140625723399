<template>
  <div class="ambition-section">
    <h2 class="heading-1 pb-0 mb-3">
      We’re an ambitious and smart team with a shared mission
    </h2>
    <p class="ambition-section-description mb-4">
      Our shared values keep us connected and guide us as one team.
    </p>
    <div class="ambition-section-blocks">
      <div class="ambition-section-single-block">
        <img src="../../assets/img/care_icon.svg" alt="image not found" />
        <p class="ambition-section-single-block-heading">Care about our team</p>
        <p class="ambition-section-single-block-description">
          Understand what matters to our employees. Give them what they need to
          do their best work.
        </p>
      </div>
      <div class="ambition-section-single-block">
        <img src="../../assets/img/care_icon.svg" alt="image not found" />
        <p class="ambition-section-single-block-heading">Care about our team</p>
        <p class="ambition-section-single-block-description">
          Understand what matters to our employees. Give them what they need to
          do their best work.
        </p>
      </div>
      <div class="ambition-section-single-block">
        <img src="../../assets/img/care_icon.svg" alt="image not found" />
        <p class="ambition-section-single-block-heading">Care about our team</p>
        <p class="ambition-section-single-block-description">
          Understand what matters to our employees. Give them what they need to
          do their best work.
        </p>
      </div>
      <div class="ambition-section-single-block">
        <img src="../../assets/img/care_icon.svg" alt="image not found" />
        <p class="ambition-section-single-block-heading">Care about our team</p>
        <p class="ambition-section-single-block-description">
          Understand what matters to our employees. Give them what they need to
          do their best work.
        </p>
      </div>
      <div class="ambition-section-single-block">
        <img src="../../assets/img/care_icon.svg" alt="image not found" />
        <p class="ambition-section-single-block-heading">Care about our team</p>
        <p class="ambition-section-single-block-description">
          Understand what matters to our employees. Give them what they need to
          do their best work.
        </p>
      </div>
      <div class="ambition-section-single-block">
        <img src="../../assets/img/care_icon.svg" alt="image not found" />
        <p class="ambition-section-single-block-heading">Care about our team</p>
        <p class="ambition-section-single-block-description">
          Understand what matters to our employees. Give them what they need to
          do their best work.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
