<template>
  <section>
    <div class="container">
      <div class="row mb-4">
        <div class="col-lg-12">
          <ul
            class="nav nav-pills mb-3 details-tabs"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home11"
                type="button"
                role="tab"
                aria-controls="pills-home11"
                aria-selected="true"
              >
                Details
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile11"
                type="button"
                role="tab"
                aria-controls="pills-profile11"
                aria-selected="false"
              >
                Size
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact11"
                type="button"
                role="tab"
                aria-controls="pills-contact11"
                aria-selected="false"
              >
                Review
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-12">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home11"
              role="tabpanel"
              aria-labelledby="pills-home-tab11"
            >
              <p>
                SP V3 face mask was designed with the active person in mind.
              </p>
              <p>
                DISCLAIMER: We will be phasing out of our original V3 face masks
                and some sizes/ colours will be fulfilled in the new version.
              </p>
              <p>
                Enim in eros, amet purus lorem. Sed tempor et justo, dictumst
                duis. Faucibus mollis urna, consectetur aliquam amet magna.
                Ultrices est, quis ac adipiscing. Tellus eu accumsan id dui,
                mauris, ultricies. Ut dui aliquet tempus nunc justo adipiscing
                congue diam. Sit turpis id non pretium urna, elementum
                consectetur. Lobortis amet sodales id morbi arcu. At quam ante
                adipiscing integer cras venenatis. Tortor, sit ac, nulla nec
                turpis ut nisi. Convallis condimentum ipsum enim nec lacus.
                <br />
                Lectus maecenas in morbi adipiscing auctor eget odio risus ac.
                Vulputate vel pellentesque viverra mattis faucibus et nulla
                dignissim. Nulla ut iaculis proin quam enim magna tristique
                dictumst scelerisque. Quam facilisi lectus nisl elit pretium
                vulputate feugiat scelerisque nunc. Quam diam sagittis tortor
                venenatis eu semper dignissim. Pellentesque blandit donec id et,
                egestas volutpat volutpat vel. Laoreet lectus sit ultrices urna
                egestas in lectus quis libero. Tempus etiam interdum ut et
                mauris imperdiet. Morbi ut a, justo, pharetra pretium nibh non
                ornare vulputate. Ultrices integer fermentum, proin tellus
                libero, sit purus. Eget sapien volutpat phasellus nulla id
                tellus enim, vitae. Ut urna lorem maecenas pharetra purus.
                <br />
                Ut scelerisque eget proin adipiscing cursus ante eu. Quis mattis
                pulvinar egestas faucibus vitae at. Est aliquet eu venenatis,
                pellentesque tortor, metus malesuada massa, fermentum. Fames
                purus orci pharetra tortor diam ut eget vulputate sed. Consequat
                nunc at ut scelerisque. Viverra purus aliquam tellus a phasellus
                at. <br />
                Cras aliquam eu ut auctor orci et scelerisque quis. Amet sit
                aenean vitae sit quis. Aliquam non risus justo lacinia in dictum
                in sed vitae. Ipsum lobortis elementum gravida egestas amet,
                quis. Sollicitudin orci, pharetra, convallis donec velit.<br />
                Mauris nunc, varius maecenas ac neque, sem scelerisque. Eu diam
                neque, nunc, facilisis sit elit. Lorem amet, eget ut vulputate
                donec. Eu elementum sed sit nunc. Feugiat pharetra, nisl sed
                sagittis. Dictum nisi, ut metus neque. Dolor cursus dictumst nam
                ullamcorper. Pellentesque in auctor massa amet. Pellentesque nam
                nam sagittis, fames feugiat nunc scelerisque pharetra at.
                Lobortis faucibus mi in suspendisse in proin. Vestibulum in odio
                platea et, sit arcu duis. Enim adipiscing sit elit.
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile11"
              role="tabpanel"
              aria-labelledby="pills-profile-tab11"
            >
              <div class="row">
                <!-- <div class="boxed d-flex">
                  <div  v-for="(sizee,index) in size" :key="(sizee,index)">
                    <input type="radio" :id="`s_${index}`" name="size" value="" />
                    <label :for="`s_${index}`">{{sizee}}</label>
                  </div>
                </div> -->
              </div>
            </div>
              <div
                class="tab-pane fade"
                id="pills-contact11"
                role="tabpanel"
                aria-labelledby="pills-contact-tab11"
              >
                <h4>Review</h4>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["size"],
};
</script>
<style scoped>
.boxed label {
  display: inline-block;
  width: auto;
  padding: 10px 20px;
  margin: 0px 10px;
  border: solid 1px #ccc;
  transition: all 0.3s;
}

.boxed input[type="radio"] {
  display: none;
}

.boxed input[type="radio"]:checked + label {
  border: solid 1px green;
}
</style>
