<template>
  <section class="featured-design py-5 bg-white">
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-5">
          <div class="row">
            <div class="col-lg-12 text-center border-right border-secondery">
              <div
                class="tab-content row d-flex justify-content-center align-items-center"
                id="myTabContent"
              >
                <div
                  class="tab-pane fade col-lg-12"
                  :class="index == 0 ? 'active show' : ' '"
                  :id="`_${index}`"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  v-for="(productPhotos, index) in photos"
                  :key="(productPhotos, index)"
                >
                  <img class="img-fluid w-100" v-bind:src="productPhotos" />
                </div>
              </div>
            </div>
            <div class="col-lg-12 text-center">
              <ul
                class="nav nav-tabs row text-center pro-details product-list-bottom"
                id="myTab"
                role="tablist"
              >
                <li
                  class="nav-item col-lg-2 mb-2 pe-0 mt-3"
                  v-for="(productPhotos, index) in photos"
                  :key="(productPhotos, index)"
                >
                  <img
                    class="img-fluid nav-link w-100 p-0"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    :data-bs-target="`#_${index}`"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    v-bind:src="productPhotos"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-7 align-items-center">
          <div class="pt-5">
            <h2 class="heading-1">{{ products.name }}</h2>
            <h3 class="heading-1 mt-3" style="color: #f95373">
              {{ products.main_price }}
            </h3>
            <p class="mt-4" v-html="innerHtml"></p>
        
            <div class="col-md-4 mb-3" v-for="(sizee,index) in size" :key="(sizee,index)">
              <label for="" class="form-label fw-bold">{{sizee.title}}</label>
                <select :name='sizee.title' id="" class="form-control">
                  <option value="">Select Option</option>
                  <option :value="options" v-for="options in sizee.options" :key="options">{{options}}</option>
                </select>
            </div>
            <div class="row">
              <div class="col-lg-5 text-center">
                <button
                  class="btn btn-outline-dark"
                  @click="minusCart()"
                  style="padding: 6px 15px"
                >
                  -
                </button>
                <div class="btn">{{ items }}</div>
                <button class="btn btn-outline-dark" @click="addCart()">
                  +
                </button>
              </div>
              <div class="col-lg-3">
                <a
                  class="art-button d-block text-center"
                  @click="addToCart(products.id, items)"
                  >Add To Cart</a
                >
              </div>
              <div class="col-lg-4">
                <router-link class="art-button d-block text-center" @click="addToCart(products.id, items)" to="/cart">Buy Now</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ProductDetailsSubPage :size="size" />
</template>

<script>
import ProductDetailsSubPage from "./ProductDetailsSubPage.vue";

export default {
  components: {
    ProductDetailsSubPage,
  },
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      products: {},
      photos: [],
      items: 1,
      size: [],
      description: "",
    };
  },
  methods: {
    addToCart(id, quantity) {
      let formData = { id: id, quantity: quantity };
      this.$store
        .dispatch("ADDTOCART", formData)
        .then((success) => {
          if (success) {
            this.$store.dispatch("GET_CART_DEATILS");
          }
        })
        .catch((error) => {
          // this.$router.push("");
        });
    },
    addCart() {
      if (this.items < 5) {
        this.items = this.items + 1;
      }
    },
    minusCart() {
      if (this.items > 1) {
        this.items = this.items - 1;
      }
    },
  },
  beforeMount() {
    this.$store
      .dispatch("GET_PRODUCTS_DEATILS", this.id)
      .then((success) => {
        this.products = success.data.data[0];
        this.photos = success.data.data[0].photos;
        this.size = success.data.data[0].choice_options;
        this.description = success.data.data[0].description;
      })
      .catch((error) => {
        console.log(error.data);
      });
  },
  computed: {
    innerHtml() {
      return this.description;
    },
  },
};
</script>
<style scoped>
select  {
  -webkit-appearance: menulist !important;
    -moze-appearance: menulist !important;
    appearance: menulist !important;

}
</style>