<template>
  <div class="row mt-4">
    <div class="col-lg-12 text-start mb-3">
      <label class="mb-2">Email Address *</label>
      <div class="input-div d-flex justify-content-center align-items-center">
        <img src="../../assets/img/mail.png" class="img-fluid ps-3" />
        <Field
          name="email_or_phone"
          type="email"
          class="form-control"
          placeholder="Enter Email "
        />
      </div>
      <ErrorMessage name="email_or_phone" />
    </div>
    <div class="col-lg-12 text-start mb-3">
      <label class="mb-2">Shop Name *</label>
      <div class="input-div d-flex justify-content-center align-items-center">
        <img
          src="../../assets/img/iconoir_small-shop-alt.png"
          class="img-fluid ps-3"
        />
        <Field
          name="shop_name"
          type="text"
          class="form-control"
          placeholder="Enter shop Address"
        />
      </div>
      <ErrorMessage name="shop_name" />
    </div>
    <div class="col-lg-12 text-start">
      <label class="mb-2">Password *</label>
      <div class="input-div d-flex justify-content-center align-items-center">
        <img src="../../assets/img/lock.png" class="img-fluid ps-3" />
        <Field
          name="password"
          type="password"
          class="form-control"
          placeholder="Password"
        />
      </div>
      <ErrorMessage name="password" />
    </div>
    <div class="col-lg-12 text-start">
      <label class="mb-2">Artist Type *</label>
      <div class="input-div d-flex justify-content-center align-items-center">
        <Field name="artist_type" as="select" class="form-select form-control">
          <option value="">Select Type of artist</option>
          <option :value="list.id" v-for="list in artistList" :key="list.id">
            {{ list.name }}
          </option>
        </Field>
      </div>
      <ErrorMessage name="artist_type" />
    </div>

    <div class="col-lg-12 mt-4 text-center">
      <div class="sec-button-div">
        <button class="art-button signin-btn d-block w-100 border-0">
          Sign Up
        </button>
        <p>
          Have an account ?
          <router-link class="" :to="'/login'">Login</router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  props: ["artistList"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
};
</script>
