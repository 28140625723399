<template>
  <section class="pb-5 pt-3 login-section mb-5">
    <div class="container pb-5 mb-5">
      <div class="row h-100 d-flex justify-content-center align-items-center">
        <div class="col-lg-7 text-center">
          <h2 class="heading-1 pb-3">It’s simple to get started!</h2>

          <p class="px-4">
            Vitae vel sit nam iaculis auctor libero nisl. Dui a diam nam laoreet
            sed. At placerat lectus ultrices
          </p>

          <div class="row mt-4">
            <div class="col-lg-12 text-start mb-3 text-center">
              <label class="chooseFile" for="chooseFile">
                <img
                  src="../../assets/img/bi_file-image.png"
                  class="img-fluid"
                />
                <h4 class="mb-2">
                  Drop your image here, or <span>Browse</span>
                </h4>
                <p class="mb-0">
                  We recomanded high-resulation JPEG, PNG, GIF with minimun
                  1000*1000px
                </p>
                <input type="file" id="chooseFile" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row mt-4 mb-5 h-100 d-flex justify-content-center align-items-center sec-button-div"
      >
        <div class="col-lg-2 text-center">
          <button class="art-button d-block w-100 border-0">Get Started</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
