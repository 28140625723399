<template>
  <div class="card mt-3">
    <div class="mb-3">
      <div class="card-body address-section">
        <div class="row">
          <div
            v-for="address in this.$store.state.address.addressList"
            :key="address.id"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  :value="address.id"
                  @click="saveAddress(address.id)"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  <h6>
                    <span class="fw-bold">{{ address.name }}</span>
                    <span class="badge bg-secondary">{{
                      address.address_type
                    }}</span>
                  </h6>
                </label>
              </div>
              <div>
                <button
                  class="btn btn-sm btn-warning"
                  data-bs-toggle="modal"
                  data-bs-target="#addressModal"
                  @click="getProfileDetails(address.id)"
                >
                  Edit
                </button>
              </div>
            </div>
            <div class="ps-4">
              <p class="fs-sm mb-0">
                {{ address.address }},{{ address.landmark }} <br />
                {{ address.city_name }},{{ address.state_name }},{{
                  address.postal_code
                }}
                <br />+91-{{ address.phone }}
              </p>
            </div>
            <div class="px-4 my-0">
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button
        class="btn btn-sm btn-danger border-0 mx-3"
        data-bs-toggle="modal"
        data-bs-target="#addAddressModal"
      >
        Add New Address
      </button>
    </div>
  </div>

  <!-- Button trigger modal -->
  <!-- Modal -->

  <div
    class="modal fade"
    id="addAddressModal"
    tabindex="-1"
    aria-labelledby="addAddressModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="float: right"
          ></button>
          <AddAddressPage></AddAddressPage>
        </div>
      </div>
    </div>
  </div>
  <div 
    class="modal fade"
    id="addressModal"
    tabindex="-1"
    aria-labelledby="addressModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="float: right"
          ></button>
          <AddressPage v-if="flag" :addressData="addressData"></AddressPage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressPage from "../Address/EditAddressPage.vue";
import AddAddressPage from "../Address/AddAddressPage.vue";

export default {
  components: {
    AddressPage,
    AddAddressPage,
  },
  data() {
    return {
      addressData: {},
      flag: true,
    };
  },
  mounted() {
    this.$store
      .dispatch("GET_ADDRESS_DETAILS")
      .then((success) => {})
      .catch((error) => {
        console.log(error.data);
      });
      this.$store.commit("updateCartAddress",null);
  },
  methods: {
    getProfileDetails(id) {
      this.flag = false;
      this.addressData = {};
      this.$store
        .dispatch("GET_ADDRESS_DETAILS_BY_ID", id)
        .then((success) => {
          this.addressData = success.data.data[0];
          // console.log(this.addressData);
          this.flag = true;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    saveAddress(id) {
      this.$store.commit("updateCartAddress",id)
    },
    addNewAddress() {
      this.flag = false;
      this.addressData = {};
      this.flag = true;
    },
  },
};
</script>
<style scoped>
.fs-sm {
  font-size: 14px;
}
.address-section {
  overflow-y: scroll;
  height: 250px;
}
</style>
