<template>
  <section class="pb-5 pt-0">
    <img src="../../assets/img/banner-profile.png" class="img-fluid" />
    <div class="container pb-5">
      <div class="row">
        <ArtistProfileComponent></ArtistProfileComponent>
        <div class="col-lg-9">
          <div class="row mt-5 mb-4">
            <div class="col-lg-12">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="btn btn-dark px-4 me-2"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Projects
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="btn"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Designs
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div class="row mt-3">
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-1.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-2.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-3.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-4.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-5.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-6.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-7.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-8.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img class="img-fluid" src="../../assets/img/pro-9.png" />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-10.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-11.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/pro-12.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
              </div>
              <!-- card-row ended -->
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div class="row mt-3">
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
                <div class="col-lg-4 col-sm col-12">
                  <a class="card-box" href="#">
                    <img
                      class="img-fluid w-100"
                      src="../../assets/img/artist-profile.png"
                    />
                    <div class="py-3">
                      <h6 class="card-h-1">Featured Designs</h6>
                      <h6 class="card-h-2">by username</h6>
                      <h6 class="card-h-3">123 AED</h6>
                    </div>
                  </a>
                </div>
              </div>
              <!-- card-row ended -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ArtistProfileComponent from "./ArtistProfileComponent.vue";
export default {
  components: {
    ArtistProfileComponent,
  },
};
</script>
