<template>
  <div class="container-fluid">
    <div class="cntr-div w-100">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h1>Shop from <span>Creatives</span></h1>
          <h1>Across the UAE</h1>
        </div>
      </div>
      <div class="col-lg-5 text-center">
        <h6 class="pt-3 pb-4">
          Vitae vel sit nam iaculis auctor libero nisl. Dui a diam nam laoreet
          sed. At placerat lectus ultrices ut maecenas. Fermentum e
        </h6>
        <router-link class="art-button" :to="'/products'"
          >Browse Designs</router-link
        >
        <router-link class="art-button-o ms-4" :to="'/howdudeworks'"
          >How it Works</router-link
        >
      </div>
    </div>
  </div>
  <FeaturedComponent></FeaturedComponent>
  <!-- <CategoryComponent></CategoryComponent> -->
  <ArtistsComponent></ArtistsComponent>
  <ProductsComponent />
</template>

<script>
import ArtistsComponent from "../components/ArtistsComponent.vue";
import FeaturedComponent from "../components/FeaturedProducts/FeaturedListComponent.vue";
import CategoryComponent from "../components/Category/CategoryList.vue";
import ProductsComponent from "../components/Product/ProductListPage.vue";

export default {
  components: {
    ArtistsComponent,
    FeaturedComponent,
    CategoryComponent,
    ProductsComponent,
  },
};
</script>
